import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import termsImg from '../assets//images/terms-of-service.jpg'

const TermsOfService = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Terms of Service" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Terms of Service" 
            />
            <section className="terms-of-service-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="terms-of-service-content">
                                <img src={termsImg} alt="tt" />
                                <p><i>These Terms & Conditions were last updated on January 1, 2020.</i></p>
                                <h3>1. Introduction</h3>
                                <blockquote className="blockquote">
                                    <p>theParse Holding Limited and its affiliates (listed in the “Affiliates” section in our Privacy Policy (collectively “theParse” “us” and “we”) and third parties we partner with use cookies and similar technologies on theParse websites (including mobile sites) and social media sites (“Sites”), mobile applications (Apps) (collectively “Online Services”) to give users (“you” or “your”) the best possible browsing experience.</p>
                                </blockquote>
                                <h3>2. Personal data we collect</h3>
                                <p>Personal data is any information that identifies you as an individual or relates to you as an identifiable individual.</p>
                                <p>We use tools like cookies, web beacons, analytics services, and advertising providers to gather the data listed above. Some of these tools offer you the ability to opt out of data collection.</p>
                                <p>Depending on how you interact with us, personal data we collect may include:</p>
                                <ol>
                                    <li>your name;</li>
                                    <li>email address;</li>
                                    <li>postal address;</li>
                                    <li>telephone number;</li>
                                    <li>log-in and account information for authentication purposes and account access to theparse™ platforms;</li>
                                    <li>social media account information.</li>
                                </ol>
                                <p>We may also collect other information that does not personally identify you. Such other information includes browser and device information, website and application usage data, IP addresses, demographic information such as marketing preferences, geographic location, primary language, and information collected through cookies and other technologies or information that has been anonymised or aggregated. If we link this information with your personal data, we will treat that linked information as personal data.</p>
                                <h3>3. Sensitive personal data</h3>
                                <p>We do not collect sensitive personal data about you, e.g. information relating to your health, religion, political beliefs, race or sexual orientation and ask that you do not send or provide this information to us.</p>
                                <h3>4. Legal basis for processing your personal data</h3>
                                <p>When we process your personal data in connection with the purposes set out in this Privacy Statement, we may rely on one or more of the following legal bases, depending on the purpose for which the processing activity is undertaken and the nature of our relationship with you:</p>
                                <ol>
                                    <li>Our legitimate interests (or those of a third party with whom we share your personal data) for the purpose of managing, operating or promoting our business, including direct marketing, and intra-inq. group transfers of personal data for business and administrative purposes, except where such interests are overridden by your interests or fundamental rights or freedoms which require protection of personal data.</li>
                                    <li>where this is necessary to comply with a legal obligation on us.</li>
                                    <li>to protect the vital interests of any individual.</li>
                                    <li>where you have consented.</li>
                                </ol>
                                <h3>5. Use of your personal data</h3>
                                <p>We may use your personal data to:</p>
                                <ol>
                                    <li>enable you to effectively use and to improve our product and solution. For example, to:</li>
                                    <li>perform administrative and business functions and internal reporting.</li>
                                    <li>send administrative information to you.</li>
                                    <li>obtain feedback from you about our product and solutions including through client satisfaction surveys, in which event, we will only use your personal data for the sole purpose of sending you a survey.</li>
                                    <li>respond to your inquiries and fulfill requests by you.</li>
                                    <li>update our records and keep contact details up to date.</li>
                                </ol>
                                <p>We engage in these activities to manage our contractual relationship with you, to comply with our legal obligations, or for our legitimate business interests.</p>
                                <p>Provide you with marketing materials and to personalise your experience. For example, to:</p>
                                <ol>
                                    <li>send marketing communications to you.</li>
                                    <li>provide you with a more personalised experience when you interact with us.</li>
                                    <li>enable you to subscribe to our newsletters and mailing lists.</li>
                                    <li>We engage in these activities with your consent or for our legitimate business interests.</li>
                                </ol>
                                <p>achieve our business purposes and analyse information. For example, to:</p>
                                <ol>
                                    <li>establish, manage, and maintain our business relationship with you.</li>
                                    <li>recruit staff.</li>
                                    <li>process and respond to privacy requests, questions, concerns and complaints.</li>
                                    <li>fulfil legal and contractual obligations.</li>
                                </ol>
                                <p>We engage in these activities to manage our contractual relationship with you, to comply with a legal obligation and for our legitimate business interests.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                        <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/cookie-policy">
                                                Cookie Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li className='active'>
                                            <Link to="/terms-of-service">
                                                Terms of Service
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                {/* <div className="widget widget_recent_courses">
                                    <h3 className="widget-title">Recent Courses</h3>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg1" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$49.00</span>
                                            <h4 className="title usmall"><Link to="#">The Data Science Course 2020: Complete Data Science Bootcamp</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg2" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$59.00</span>
                                            <h4 className="title usmall"><Link to="#">Java Programming MasterclassName for Software Developers</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg3" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$69.00</span>
                                            <h4 className="title usmall"><Link to="#">Deep Learning A-Z™: Hands-On Artificial Neural Networks</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>
                                </div> */}

                                <div className="widget widget_tag_cloud">
                                    <h3 className="widget-title">Popular Tags</h3>

                                    <div className="tagcloud">
                                    <Link to="/about-us">who is theParse? <span className="tag-link-count"> (3)</span></Link>
                                    <Link to="/contact">how do I contact theParse? <span className="tag-link-count"> (3)</span></Link>
                                    {/* <Link to="#">Braike <span className="tag-link-count"> (2)</span></Link>
                                    <Link to="#">Fashion <span className="tag-link-count"> (2)</span></Link>
                                    <Link to="#">Travel <span className="tag-link-count"> (1)</span></Link>
                                    <Link to="#">Smart <span className="tag-link-count"> (1)</span></Link>
                                    <Link to="#">Marketing <span className="tag-link-count"> (1)</span></Link>
                                    <Link to="#">Tips <span className="tag-link-count"> (2)</span></Link> */}
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default TermsOfService;